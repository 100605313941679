import { localeForMarket } from '@bemlo/utils'
import { Market } from '@bemlo/enums'

import { MarketFeature } from '../enums'
import { MarketConfig } from '../types'
import translations from '../../translations/locales/sv'

import { baseMarketMetadata } from './base-meta'
import { Country, Currency } from '../../generated/graphql'

// Cannot use getTranslation here, becuase this is used outside the scope of Webpack
const t = translations.meta

export const marketConfig: MarketConfig = {
  market: Market.SE,
  locale: localeForMarket(Market.SE),
  localeName: 'sv-SE',
  country: {
    currency: Currency.SEK,
    name: 'Sweden',
    value: Country.SWEDEN,
    wikidataCode: 'Q34',
  },
  features: [
    MarketFeature.ABOUT_US,
    MarketFeature.BLOG,
    MarketFeature.COLLECTIVE_AGREEMENTS,
    MarketFeature.COMPANY,
    MarketFeature.CONSULTANT_COUNTS,
    MarketFeature.CV,
    MarketFeature.DRIP_RATE,
    MarketFeature.FAQ,
    MarketFeature.JOB_WORKPLACE,
    MarketFeature.LANDING_PAGE,
    MarketFeature.NORWAY_TOGGLE,
    MarketFeature.PROFESSION_SELECTOR,
    MarketFeature.PROFILE,
    MarketFeature.REGION_SPECIFIC,
    MarketFeature.SALARY,
    MarketFeature.VARDFORBUNDET,
  ],
  meta: {
    ...baseMarketMetadata,
    companyNamePossessive: t.company_name_possessive,
    siteDescription: t.site_description,
    siteKeywords: t.site_keywords,
    siteNamePossessive: t.site_name_possessive,
    email: 'info@bemlo.se',
    emailDomain: 'bemlo.se',
    siteCareerUrl: 'https://career.bemlo.se',
    siteDomain: 'bemlo.se',
    siteLocale: 'sv_SE',
    siteUrl: 'https://www.bemlo.se',
  },
  segmentProdKey: 'loyEBzYSZL6BPM344yhMtP5R7EKyohYO',
  reviewsFrom: [Market.SE],
}
